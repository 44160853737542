const main = {
  background: "#f9f9f9",
  contrast: "#232323",
  secondary1: "#8c8c8c",
  secondary2: "#aaaaaa",
  secondary3: "#ececec",
}

const invert = {
  background: "#232323",
  contrast: "#f9f9f9",
  secondary1: "#8c8c8c",
  secondary2: "#aaaaaa",
  secondary3: "#ececec",
}

export { main, invert }
