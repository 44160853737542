import React from "react"
import { ThemeProvider } from "styled-components"

import * as themes from "../styles/themes"

export const THEME_NAMES = {
  MAIN: "main",
  INVERT: "invert",
}
export const ThemeContext = React.createContext(THEME_NAMES.MAIN)

function ThemeManager({ children }) {
  const [theme, setTheme] = React.useState(THEME_NAMES.MAIN)
  const toggleTheme = React.useCallback(() => {
    setTheme(_theme =>
      _theme === THEME_NAMES.MAIN ? THEME_NAMES.INVERT : THEME_NAMES.MAIN
    )
  }, [])
  return (
    <ThemeContext.Provider value={toggleTheme}>
      <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeManager
